<template>

 <v-container class="pa-2">

  <v-toolbar class="mymidgreen"
    dark
    tabs
  >
  <v-toolbar-title v-text="title"></v-toolbar-title>
<!--     <v-spacer></v-spacer>
    <v-btn class="mx-2 pa-1 ma-0 orbitron" @click="filters_dialog=true">
      Factions
    </v-btn>
    <v-btn class="mx-2 pa-1 ma-0 orbitron" @click="filters_dialog=true">
      Filters
    </v-btn>
    <v-btn class="mx-2 pa-1 ma-0 orbitron" @click="columns_dialog=true">
      Columns
    </v-btn>
-->
    <v-spacer/>
    <v-btn v-if="sortBy.length>0" class="mx-2 pa-1 ma-0 primary" @click="unsortColumns">Unsort</v-btn>

    <v-btn v-if="filter_enabled" class="mx-2 pa-1 ma-0 primary" @click="filter_enabled=!filter_enabled">Unfilter</v-btn>
    <v-btn v-else class="mx-2 pa-1 ma-0 primary" @click="filter_enabled=!filter_enabled">Filter</v-btn>

    <ChooserDialog class="mx-2 pa-1 ma-0 orbitron" :dialogTitle="'Choose Columns'" :buttonText="'Cols'" :items="availableExtraColumns" :initialSelection="extraColumns" @updateSelection="updateSelectedColumns" />

  </v-toolbar>

    <v-row v-show="filter_enabled" class="mx-2">
      <v-col v-for="col in filteredColumns" :key="col" cols="12" sm="6" md="4">
        <v-select
          :items="columnOptions(col)"
          item-text="name"
          item-value="value"
          v-model="filters[col]"
          :label="'Filter by ' + $formatDataLabel(col)"
          clearable
        ></v-select>
      </v-col>
    </v-row>

  <v-data-table
    :headers="headers"
    :items="items"
    :page.sync="page"
    :items-per-page.sync="itemsPerPage"
    :server-items-length="totalItems"
    multi-sort
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortAsc"
    class="elevation-1 orbitron my-data-table"
    :loading="loading"
    loading-text="Loading... Please wait"
    :footer-props="{'items-per-page-options':[10, 20, 50]}"
  >

    <template v-slot:no-results>
      Sorry, there was a problem fetching the data
    </template>

    <template v-slot:item="{ item, index }">
      <tr :class="index % 2 === 0 ? 'even-row' : 'odd-row'">
        <td>
          <v-avatar class="mr-2 ml-0 avatar-border icon-magnify"
            size="36px"
          >
            <img
              v-if="item.asset_url.length>0"
              alt=""
              :src="replaceImageSuffixWithThumbnail('https://doh.nyc3.cdn.digitaloceanspaces.com'+item.asset_url)"
            >
            <v-icon
              v-else
              color="white"
              v-text="'!'"
            ></v-icon>
          </v-avatar>
          {{ item.owner ? item.owner : item.character }}
        </td>

        <td v-if="headers.indexOf('faction')">
            <v-img
              v-if="item.asset_url.length>0"
              alt=""
              :src="'https://doh.nyc3.cdn.digitaloceanspaces.com'+faction_flag_asset_urls[item.faction]"
              height="30px"
              width="36px"
              class="avatar-border icon-magnify"
            />
        </td>

        <td v-for="header in headers" v-if="(header.value!='owner'&&header.value!='character')&&header.value!='faction'" :key="header.value">{{ item[header.value] }}</td>
      </tr>
    </template>

    <template v-slot:footer.page-text="{ pageStart, pageStop }">
      Showing {{ pageStart }} to {{ pageStop }}
    </template>

<!-- 
    <template v-slot:footer>
      <tr>
        <td colspan="100%">
          Custom footer content here...
        </td>
      </tr>
    </template>
 -->
  </v-data-table>

<!--   <div v-if="pageCount>0" class="text-center pt-2">
    <v-pagination
      v-model="page"
      :length="pageCount"
    ></v-pagination>
  </div>
 -->

</v-container>

</template>

<script>
  import axios from 'axios';
  import ChooserDialog from "@/components/ChooserDialog.vue";

  export default {

    props: {
      title: {
        type: String,
        required: true,
      },
      type: {
        type: String,
        required: true,
      },
      apiEndpoint: {
        type: String,
        required: true,
      },
    },

    components: {
      ChooserDialog,
    },

    data () {
      return {
        columns_dialog: false,
        filters_dialog: false,

        baseColumns: ['owner', 'asset_url', 'faction'],
        extraColumns: [''],
        availableExtraColumns: [],

        filter_enabled: false,
        filteredColumns: ['faction', 'area', 'region'],
        // filters: {experience_level:{value:20,operator:"<"}},
        filters: {},
        filtersObjectInternal: {},

        loading: true,
        headers: [],
        items: [],

        page: 1,
        itemsPerPage: 10,
        totalItems: 0,
        loading: false,
        sortBy: ['level'], // replace with your default column
        sortAsc: [false],
        faction_flag_asset_urls: {},

        data_last_modified: null,

      }
    },

    watch: {
      '$route.query': {
        handler() {
          this.setComponentState();
        },
        immediate: true,
      },
      page: {
        handler() {
          this.persistComponentState();
          this.updatePage();
        },
        deep: true,
      },
      sortBy: {
        handler() {
          console.log('SortBy');
          // this.persistComponentState();
          // this.updatePage();
          // if this is uncommented it messes things up
        },
        deep: true,
      },
      sortAsc: {
        handler() {
          console.log('SortAsc');
          this.persistComponentState();
          this.updatePage();
        },
        deep: true,
      },
      itemsPerPage: {
        handler() {
          this.persistComponentState();
          this.updatePage();
        },
        deep: true,
      },
      filtersObject: {
        handler() {
          this.persistComponentState();
          this.updatePage();
        },
        deep: true,
      },
      filter_enabled: {
          handler() {
          if (!this.filter_enabled) {
            this.filters = {};
          }
        }
      }
    },

    computed: {
      pageCount() {
        console.log(this.totalItems);
        console.log(this.itemsPerPage);
        console.log(Math.ceil(this.totalItems / this.itemsPerPage));
        return Math.ceil(this.totalItems / this.itemsPerPage);
      },

      filtersObject: {
        get() {
          let result = {};
          for (let key in this.filters) {
            if (this.filters[key] !== null) {
              result[key] = { value: this.filters[key], operator: "=" };
            } else {
              delete result[key];
            }
          }
          return result;
        },
        set(val) {
          this.filtersObjectInternal = val;
        }
      }

    },

    methods: {
      async fetchData() {
        this.loading = true;
        let url = `${this.apiEndpoint}?limit=${this.itemsPerPage}&extra_cols=${JSON.stringify(this.extraColumns)}&offset=${(this.page - 1) * this.itemsPerPage}&sort=${JSON.stringify(this.sortBy)}&asc=${JSON.stringify(this.sortAsc)}&filter=${JSON.stringify(this.filtersObject)}`;
        console.log(url);
        this.$axios.get(url)
          .then(response => {
            console.log(response);
            let exclude = ['asset_url'];
            this.faction_flag_asset_urls = response.data.faction_flag_asset_urls;
            this.columns = response.data.base_columns.concat(response.data.extra_columns);
            this.headers = response.data.base_columns.concat(response.data.extra_columns)
              .filter(column => !exclude.includes(column))
              .map(column => ({
                text: this.$formatDataLabel(column),
                align: column == 'owner' ? 'start' : '',
                value: column,
                sortable: true
              }));

            console.log(this.headers);

            this.items = response.data.entries;
            this.totalItems = response.data.total_entries;
            this.availableExtraColumns = response.data.available_extra_columns;
          })
          .catch(err => {
            console.error(err);
          })
          .finally(() => {
            console.log('Done loading!');
            this.loading = false;
          });

      },
      updatePage() {
        this.fetchData();
      },
      updateItemsPerPage() {
        this.fetchData();
        this.persistComponentState();
      },
      updateFilter(filter) {
        this.filters = filter;
        this.fetchData();
      },

      updateSelectedColumns(selected) {
        this.extraColumns = selected;
        this.fetchData();
        this.persistComponentState();
      },

      unsortColumns() {
        this.sortBy = [];
        this.sortAsc = [];
      },

      setComponentState() {

        this.page = 1;

        const query = this.$route.query;
        console.log(query);
        if (query && typeof query === 'object') {
            // Decode the filters parameter
            const filters = JSON.parse(decodeURIComponent(query.filters || '{}'));

            // Check if all filters are null
            const allFiltersNull = Object.values(filters).every(value => value === null);

            this.filter_enabled = !allFiltersNull;
        }

        // configure from presets
        let preset = query.preset;
        if (this.type == 'player') {
          if (preset == 'main') {
            this.filters = {};
            this.extraColumns = ['main_char', 'level', 'xp'];
            this.sortBy = ['level', 'xp'];
            this.sortAsc = [false, false];
          }
          if (preset == 'tcn') {
            this.filters = {};
            this.extraColumns = ['TCN_liq', 'TCN_staked', 'TCN'];
            this.sortBy = ['TCN'];
            this.sortAsc = [false];
          }
          if (preset == 'tcnearned') {
            this.filters = {};
            this.extraColumns = ['TCN_earned', 'TCN'];
            this.sortBy = ['TCN_earned', 'TCN'];
            this.sortAsc = [false, false];
          }
          if (preset == 'landlords') {
            this.filters = {};
            this.extraColumns = ['area', 'region', 'buildings'];
            this.sortBy = ['buildings'];
            this.sortAsc = [false];
          }
        }
        else if (this.type == 'character') {
          if (preset == 'main') {
            this.filters = {};
            this.extraColumns = ['player', 'role', 'level', 'xp'];
            this.sortBy = ['level', 'xp'];
            this.sortAsc = [false, false];
          }
          if (preset == 'combat') {
            this.filters = {};
            this.extraColumns = ['player', 'battles', 'battle_deaths', 'retreats'];
            this.sortBy = ['battles', 'battle_deaths', 'retreats'];
            this.sortAsc = [false, true, true];
          }
          if (preset == 'health') {
            this.filters = {};
            this.extraColumns = ['player', 'hp', 'hours_till_starve', 'energy', 'alive'];
            this.sortBy = ['alive', 'hp', 'hours_till_starve', 'energy'];
            this.sortAsc = [false, false, false, false];
          }
          if (preset == 'hungry') {
            this.filters = {};
            this.extraColumns = ['player', 'hours_till_starve', 'alive'];
            this.sortBy = ['alive', 'hours_till_starve'];
            this.sortAsc = [false, true];
          }
          if (preset == 'deaths') {
            this.filters = {};
            this.extraColumns = ['player', 'starvations', 'battle_deaths', 'deaths', 'respawns'];
            this.sortBy = ['respawns', 'deaths'];
            this.sortAsc = [false, false];
          }
        }

        if (this.$route.query.cols) {
          this.extraColumns = JSON.parse(decodeURIComponent(this.$route.query.cols));
        }
        if (this.$route.query.filters) {
          this.filters = JSON.parse(decodeURIComponent(this.$route.query.filters));
        }
        if (this.$route.query.sortBy) {
          this.sortBy = JSON.parse(decodeURIComponent(this.$route.query.sortBy));
        }
        if (this.$route.query.sortAsc) {
          this.sortAsc = JSON.parse(decodeURIComponent(this.$route.query.sortAsc));
        }
        if (this.$route.query.page) {
          this.page = Number(this.$route.query.page);
        }
        if (this.$route.query.itemsPerPage) {
          this.itemsPerPage = Number(this.$route.query.itemsPerPage);
        }
      },
      persistComponentState() {
        this.$router.replace({
          query: {
            ...this.$route.query,
            cols: encodeURIComponent(JSON.stringify(this.extraColumns)),
            filters: encodeURIComponent(JSON.stringify(this.filters)),
            sortBy: encodeURIComponent(JSON.stringify(this.sortBy)),
            sortAsc: encodeURIComponent(JSON.stringify(this.sortAsc)),
            page: this.page,
            itemsPerPage: this.itemsPerPage,
          },
        }).catch(err => {
          // Ignore the vuex err regarding  navigating to the page they are already on.
          if (
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes('Avoided redundant navigation to current location')
          ) {
            // But print any other errors to the console
            logError(err);
          }
        });
      },

      rowClass(item, type) {
        if (type === 'item') {
          return item.name !== undefined && item.calories % 2 === 0 ? 'even-row' : 'odd-row'
        }
        return ''
      },

      replaceImageSuffixWithThumbnail(url) {
          return url.replace(/(\.(jpg|jpeg|png|gif|bmp|tiff|webp))$/, '-thumbnail$1');
      },

      columnOptions(col) {
        if (col == 'faction') {
          const keys = Object.keys(this.$config.factions)
          const result = this.$config.factions.map(key => ({ name: key.name, value: key.id }));
          return result;
        }

        if (col == 'area') {
          const keys = Object.keys(this.$config.area_map_regions)
          const result = keys.map(key => ({ name: key, value: key }));
          return result;
        }

        if (col == 'region') {
          const areas = Object.values(this.$config.area_map_regions);
          const result = areas.reduce((acc, regionList) => acc.concat(regionList.map(region => ({ name: region.name, value: region.name }))), []);
          return result;
        }

        // return [...new Set(this.items.map((item) => item[col]))];
      },


    },
    mounted() {
      this.fetchData();
    },

  }
</script>


<style scoped>
  .even-row {
    background-color: #111111;
  }

  .odd-row {
    background-color: #222222;
  }

  .v-data-table-header {
      font-size: 200px!important;   /* Replace with your desired font size */
  }

  .avatar-border {
    border: 1px solid #444; /* Change to desired border color */
  }

</style>

<style>

  .theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    color: rgba(200, 255, 255, 0.7)!important;
    font-size: 0.9em;
    background-color: ;
  }

  .theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover {
    background-color: rgba(255, 255, 255, 0.05)!important; /* Change to desired hover color */
    cursor: pointer; /* Changes the cursor to a hand on hover */
  }


  .v-data-table-header__sort-badge {
    display: inline-flex;
    justify-content: left;
    align-items: center;
    border: 0px;
    border-radius: 50%;
    min-width: 8px;
    min-height: 8px;
    font-size: 0.5em;
    /* height: 18px; */
    /* width: 18px; */
  }

  .theme--dark.v-data-table .v-data-table-header__sort-badge {
     background-color: rgba(255, 255, 255, 0.0); 
/*    color: #FFFFFF;*/
  }

.icon-magnify:hover {
  transform: scale(1.8); /* Adjust the scale as per your requirement */
  transition: transform 0.3s ease-in-out; 
  z-index: 99999; /* this will make sure it's on top of other elements */
  position: relative; /* needed to apply z-index */  
}

.v-data-footer {
  color: rgba(200, 255, 255, 0.7)!important;
}

</style>