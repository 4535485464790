import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { createPinia, PiniaVuePlugin } from 'pinia'
import axios from 'axios'
import VueCompositionAPI from '@vue/composition-api'

Vue.use(VueCompositionAPI)
Vue.config.productionTip = false

Vue.prototype.$axios = axios;

// Vue.prototype.$api_endpoint = 'http://localhost:8010'
Vue.prototype.$api_endpoint = 'https://hg1.dynamicsofhegemony.com'
// Vue.prototype.$api_endpoint = 'https://hgm.dynamicsofhegemony.com'

Vue.prototype.$formatDataLabel = function(str) {
  return str.replace(/_/g, ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

async function fetchConfig() {
  try {
    const response = await axios.get(`${Vue.prototype.$api_endpoint}/ui-config`);
    console.log(response)
    return response.data;
  } catch (error) {
    console.error("API is down, loading default config:", error);
    return {

    };
  }
}

fetchConfig().then((config) => {
  // make config globally available here, for example, as a Vue prototype
  Vue.prototype.$config = config;

  const pinia = createPinia()
  Vue.use(PiniaVuePlugin)

  new Vue({
    router,
    vuetify,
    pinia,
    render: h => h(App)
  }).$mount('#app')
})
