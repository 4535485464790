import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: '#007440',
        secondary: colors.green.darken1, // #388E3C
        accent: colors.amber.darken3, // #FFC107
        error: colors.red.accent3, // #FF5252
        info: colors.blue.accent1, // #448AFF
        success: colors.green.accent3, // #4CAF50
        warning: colors.orange.accent3, // #FF9100
        // Add any other custom colors here

		mydarkgreen: '#022f19',
		mymiddarkgreen: '#022a15',
		mymidgreen: '#014927',
		mygreen: '#007440',

      }
    }
  },

	typography: {
    	fontFamily: 'Roboto, sans-serif',
	},

});
