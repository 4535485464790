<template>
  <v-container class="pa-5">

    <v-toolbar class="mymidgreen"
      dark
      tabs
    >
    <v-toolbar-title v-text="title"></v-toolbar-title>
    </v-toolbar>

    <vue-pivottable-ui
      v-model="pivotConfig"
      :data="pivotData"
      renderer-name="Table"
      :renderers="renderers"
    >
    </vue-pivottable-ui>

    <!-- {{config}} -->

  </v-container>
</template>

<script>
  import axios from 'axios';
  import { VuePivottableUi, VuePivottable, Renderer } from 'vue-pivottable'
  import 'vue-pivottable/dist/vue-pivottable.css'
  // todo:lowpri - understand why this absolute path fudge is needed
  import PlotlyRenderer from '/home/app/my-project/node_modules/@vue-pivottable/plotly-renderer'
  import ScrollRenderer from '/home/app/my-project/node_modules/@vue-pivottable/scroll-renderer'
  // import { PlotlyRenderer } from '@vue-pivottable/plotly-renderer'

          // v-model="config"
          // :data="pivotData"
          // :locale="locale"
          // :locales="locales"
          // :renderers="renderers"
          // :aggregatorName="aggregatorName"
          // :tableColorScaleGenerator="colorScaleGenerator"
          // :attributes="attributes"
          // :valueFilter="valueFilter"
          // :rows="rows"
          // :cols="cols"
          // :vals="vals"
          // :colLimit="100"
          // :rowLimit="100"
          // :async="false"
          // :disabledFromDragDrop="disabledFromDragDrop"
          // :sortonlyFromDragDrop="sortonlyFromDragDrop"
          // :hiddenFromDragDrop="hiddenFromDragDrop"
          // :sorters="sorters"
          // rowOrder="key_a_to_z"
          // :tableOptions="tableOptions"
          // :menuLimit="10000"
          // @no:filterbox="noFilterbox"

  export default {
    name: 'PivotView',

    components: {
      VuePivottableUi
    },

    props: {
      title: String,
      dataUrl: String,
    },

    data() {
      return {
        pivotData: [],
        pivotConfig: {}
      };
    },

    computed: {
      renderers () {
        return (() => ({
          Table : ScrollRenderer.Table,
          'Export Table TSV': Renderer.TableRenderer['Export Table TSV'],
          'Table Heatmap': ScrollRenderer['Table Heatmap'],
          'Table Col Heatmap': ScrollRenderer['Table Col Heatmap'],
          'Table Row Heatmap': ScrollRenderer['Table Row Heatmap'],
          'Grouped Column Chart': PlotlyRenderer['Grouped Column Chart'],
          'Stacked Column Chart': PlotlyRenderer['Stacked Column Chart'],
          'Grouped Bar Chart': PlotlyRenderer['Grouped Bar Chart'],
          'Stacked Bar Chart': PlotlyRenderer['Stacked Bar Chart'],
          'Line Chart': PlotlyRenderer['Line Chart'],
          'Dot Chart': PlotlyRenderer['Dot Chart'],
          'Area Chart': PlotlyRenderer['Area Chart'],
          'Scatter Chart': PlotlyRenderer['Scatter Chart'],
          'Multiple Pie Chart': PlotlyRenderer['Multiple Pie Chart']
        }))()
      },

      config() {
        const copiedObj = JSON.parse(JSON.stringify(this.pivotConfig));
        delete copiedObj.data;
        return copiedObj;
      }
    },

    watch: {
      dataUrl: {
        immediate: true,
        async handler(newUrl) {
          const response = await axios.get(newUrl)
          const csvData = response.data
          this.pivotData = this.csvToJson(csvData)
        }
      }
    },

    methods: {

      csvToJson(csvData) {
        const lines = csvData.split('\n').filter(line => line.trim() !== '');
        if (lines.length === 0) return [];

        const headers = lines[0].split(',');
        const jsonArr = [];

        for (let i = 1; i < lines.length; i++) {
          const obj = {};
          const currentline = lines[i].split(',');

          if (currentline.length === headers.length) {
            for (let j = 0; j < headers.length; j++) {
              obj[headers[j].trim()] = currentline[j]?.trim();
            }
            jsonArr.push(obj);
          }
        }
        
        return jsonArr;
      }

    },

    async mounted() {
      const response = await axios.get(this.dataUrl)
      const csvData = response.data // Assuming response.data contains your CSV data
      // Convert CSV to JSON object array for pivottable
      this.pivotData = this.csvToJson(csvData)
    },

  }
</script>

<style>

* {
  box-sizing: border-box;
}

/* pvtUi */
table.pvtUi {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  vertical-align: top;
  border: 1px solid #dee2e6;
  font-family: Roboto;
}
table.pvtUi > :not(caption) > * > * {
  border: 1px solid #dee2e6;
}
table.pvtUi td {
  padding: 0.5rem 0.5rem;
  overflow-x: auto;
}
table.pvtUi > tbody > tr:nth-child(2) > :nth-child(2),
table.pvtUi > tbody > tr:nth-child(3) > :nth-child(1) {
  background-color: rgba(0, 0, 0, 0.05);
}
table.pvtUi > tbody td:nth-child(1) {
  width: 25%;
}

/* pvtUi selector */
.pvtUi .pvtDropdown {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
/*
  color: #212529;
  background-color: #fff;
*/
  color: #fff;
  background-color: #000;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
  outline: none;
}

/* pvtUi selector */
.pvtUi .pvtVals :first-child {
  display: flex;
}
.pvtUi .pvtVals .pvtDropdown:not(:first-child) {
  margin-top: 0.5rem;
}
.pvtUi .pvtVals .pvtRowOrder,
.pvtUi .pvtVals .pvtColOrder {
  cursor: pointer;
  width: 15px;
  margin-left: 5px;
  user-select: none;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* pivot Axis Container */
.pvtUi .pvtAxisContainer.pvtHorizList li {
  display: inline-flex;
  margin-left: 0.125rem;
  flex-wrap: wrap;
}
.pvtUi .pvtAxisContainer.pvtVertList.pvtRows {
  vertical-align: top;
}
.pvtUi .pvtAxisContainer.pvtVertList.pvtRows li {
  display: flex;
  margin-bottom: 0.125rem;
}
.pvtUi .pvtAxisContainer li {
  align-items: stretch;
  list-style-type: none;
  cursor: move;
}
.pvtUi .pvtAxisContainer li .pvtAttr {
/*  background-color: #0d6efd;*/
  background-color: #007440;
  color: #fff;
/*  padding: 0.35em 0.65em;*/
  padding: 0px 5px;
  white-space: nowrap;
  border-radius: 0.375rem;
  border-width: 0.1px;
  user-select: none;
}
.pvtUi .pvtAxisContainer li .pvtAttr:hover:not(.disabled, .sortonly) {
/*  border-color: #0a58ca;
  background-color: #0b5ed7;
*/
/*  border-color: #014927;*/
  background-color: #014927;
}
.pvtUi .pvtAxisContainer li .pvtAttr.disabled {
  opacity: 0.65;
  cursor: default;
}
.pvtUi .pvtAxisContainer li .pvtAttr.sortonly {
  border: 1px solid #007440;
  color: #007440;
  background-color: #fff;
}
.pvtUi .pvtAxisContainer li .pvtAttr .pvtFilterBox {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  z-index: 1;
/*  background-color: #fff;*/
  background-color: #000;
  user-select: none;
  border: 1px solid #dee2e6;
  max-width: 600px;
  min-width: 210px;
  min-height: 100px;
/*  color: #000;*/
  color: #fff;
  padding: 12px 8px;
}

/* pvtFilterBox */
.sortable-chosen .pvtFilterBox {
  display: none !important;
}
.pvtFilterBox .pvtSearchContainer .pvtSearch {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right-width: 0;
  background-color: #000;
  color: #fff;
}
.pvtFilterBox .pvtSearchContainer .pvtButton:not(:last-child) {
  border-radius: 0;
}
.pvtFilterBox .pvtSearchContainer .pvtButton:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left-width: 0;
}

/* pvtTable */
table.pvtTable {
  width: 100%;
  color: #212529;
  border-color: #dee2e6;
  border-collapse: collapse;
}
table.pvtTable thead,
table.pvtTable tbody,
table.pvtTable tr,
table.pvtTable td,
table.pvtTable th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}
table.pvtTable > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: transparent;
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px transparent;
}
table.pvtTable thead {
  vertical-align: bottom;
}
table.pvtTable tbody {
  vertical-align: inherit;
}
table.pvtTable th {
  font-weight: bold;
}

/* pvtCheckContainer */
.pvtCheckContainer p {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.pvtCheckContainer p:not(:first-child) {
  margin-top: 0.125rem;
}
.pvtCheckContainer p:last-child {
  margin-bottom: 0;
}
.pvtCheckContainer p input[type="checkbox"] {
  border-radius: 0.25em;
  float: left;
  margin-left: -1.5em;
  width: 1em;
  height: 1em;
  vertical-align: top;
  border: 1px solid rgba(0, 0, 0, 0.25);
  background-color: #fff;
  background-position: center;
  background-size: contain;
  appearance: none;
}
.pvtCheckContainer p.selected input[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
  background-color: #007440!important;
  border-color: #007440!important;
}

.pvtCheckContainer p .pvtOnly {
  cursor: pointer;
  position: absolute;
  right: 0.75rem;
  color: #007440;
  font-size: 1rem;
  font-weight: 400;
  background-color: transparent;
  line-height: 1.5;
  text-align: center;
  text-decoration: underline;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 0.375rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.pvtCheckContainer .pvtOnly:hover {
  color: #0a58ca;
}

/* pvtSearch */
.pvtSearch {
  padding: 0.375rem 0.75rem;
  color: #6c757d;
  font-size: 1rem;
  font-weight: 400;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  outline: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* pvtButton */
.pvtButton {
  padding: 0.375rem 0.75rem;
  color: #6c757d;
  font-size: 1rem;
  font-weight: 400;
  background-color: transparent;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  border: 1px solid #6c757d;
  border-radius: 0.375rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.pvtButton:hover,
.pvtButton:active {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

/* pvtTriangle */
.pvtTriangle {
  cursor: pointer;
  color: #fff;
}

/* pvtFilteredAttribute */
.pvtFilteredAttribute {
  font-style: italic;
}

/* pvtFilterTextClear */
.pvtFilterTextClear {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 18px;
  cursor: pointer;
  text-decoration: none;
}
/* media css */
@media screen and (max-width: 576px) {
  .pvtRenderers {
    width: 100%;
  }

  .pvtAxisContainer {
    display: none;
  }
}
@media screen and (width: 768px) {
  .pvtRenderers {
    width: 40%;
  }
}


  .pvtAxisContainer {
    background-color: black;
/*    color: white;*/
  }
  .pvtHorizList {
    background-color: black;
/*    color: white;*/
  }
  .pvtCols {
    background-color: black;
/*    color: white;*/
  }
  .pvtUi {
    background-color: black;
/*    color: white;*/
  }
  .pvtVals {
    background-color: black;
    color: white;
  }
  .pvtDropdown {
    background-color: black;
    color: white;
  }

.selected {
  background-color: #000!important;
  color: #fff!important;
}

.pvtOutput {
  background-color: #000;
/*  color: #fff!important;*/
}

table.pvtTable thead tr th, table.pvtTable tbody tr th {
  background-color: #efe;

}

table.pvtTable thead tr th, table.pvtTable tbody tr th {
  font-size: 1.2em;  
  font-family: Roboto;
}

table.pvtTable .pvtColLabel {
  text-align: center;  
}

table.pvtTable thead tr th, table.pvtTable tbody tr th {
  text-align: center;   
  padding: 1px;
}

table.pvtTable .pvtTotalLabel {
  text-align: center;
}

table.pvtTable tbody tr td {
  font-size: 1.2em;  
  font-family: Roboto;  
  text-align: center;
}



</style>


