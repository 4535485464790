import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PlayerAnalysisView from '../views/PlayerAnalysisView.vue'
import LeaderboardView from '../views/LeaderboardView.vue'
// import TileAnalysisView from '../views/TileAnalysisView.vue'
import NewsView from '../views/NewsView.vue'
import ProductionFactionView from '../views/ProductionFactionView.vue'
import ProductionRegionView from '../views/ProductionRegionView.vue'
import ProductionFilteredView from '../views/ProductionFilteredView.vue'
import ActivitiesFactionView from '../views/ActivitiesFactionView.vue'
import ActivitiesRegionView from '../views/ActivitiesRegionView.vue'
import CasualtiesView from '../views/CasualtiesView.vue'
import LandlordsView from '../views/LandlordsView.vue'
import PlayersView from '../views/PlayersView.vue'
import EnergyUseView from '../views/EnergyUseView.vue'
import DailyActionsView from '../views/DailyActionsView.vue'
import PivotView from '../views/PivotView.vue'

Vue.use(VueRouter)

const api_endpoint = Vue.prototype.$api_endpoint;

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/news',
    name: 'news',
    component: NewsView
  },

  {
    path: '/player-leaderboards',
    name: 'playerleaderboards',
    component: LeaderboardView,
    props: route => ({
      title: 'Player Universe',
      type: 'player',
      apiEndpoint: `${Vue.prototype.$api_endpoint}/player-leaderboard`
    })
  },

  {
    path: '/character-leaderboards',
    name: 'characterleaderboards',
    component: LeaderboardView,
    props: route => ({
      title: 'Character Universe',
      type: 'character',
      apiEndpoint: `${Vue.prototype.$api_endpoint}/character-leaderboard`
    })
  },

  {
    path: '/players',
    name: 'players',
    component: PlayersView
  },

  {
    path: '/actions',
    name: 'actions',
    component: DailyActionsView
  },

  {
    path: '/production-by-faction',
    name: 'productionbyfaction',
    component: ProductionFactionView
  },
  {
    path: '/production-by-region',
    name: 'productionbyregion',
    component: ProductionRegionView
  },
  {
    path: '/production-filtered',
    name: 'productionfiltered',
    component: ProductionFilteredView
  },
  {
    path: '/activities-by-faction',
    name: 'activitiesbyfaction',
    component: ActivitiesFactionView
  },
  {
    path: '/activities-by-region',
    name: 'activitiesbyregion',
    component: ActivitiesRegionView
  },

  {
    path: '/energy-use-by-faction',
    name: 'energyusebyfaction',
    component: EnergyUseView
  },

  {
    path: '/casualties',
    name: 'casualties',
    component: CasualtiesView
  },

  {
    path: '/landlords',
    name: 'landlords',
    component: LandlordsView
  },

  {
    path: '/actions-pivot',
    name: 'actionspivot',
    component: PivotView,
    props: route => ({
      title: 'Recent Actions',
      dataUrl: `${Vue.prototype.$api_endpoint}/action-log-recent?fmt=csv`
    })
  },
  {
    path: '/transfers-pivot',
    name: 'transferspivot',
    component: PivotView,
    props: route => ({
      title: 'TCN Transfers',
      dataUrl: `${Vue.prototype.$api_endpoint}/transfers-tcn?fmt=csv`
    })
  },
  {
    path: '/players-characters-pivot',
    name: 'playerscharacterspivot',
    component: PivotView,
    props: route => ({
      title: 'Player-Characters',
      dataUrl: `${Vue.prototype.$api_endpoint}/player-characters-full?fmt=csv`
    })
  },


  // {
  //   path: '/player-analysis',
  //   name: 'playeranalysis',
  //   component: PlayerAnalysisView
  // },
  // {
  //   path: '/tile-analysis',
  //   name: 'tileanalysis',
  //   component: TileAnalysisView
  // },

  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Global guard
router.beforeEach((to, from, next) => {
  if (to.fullPath == '/') next();

  if (to.fullPath === from.fullPath) {
    next(false); // cancel the navigation
  } else {
    next();
  }
})


export default router
