<template>
  <div>
    <v-btn @click="dialog = true" class="primary">{{buttonText}}</v-btn>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>{{dialogTitle}}</v-card-title>
        <v-card-text style="max-height: 400px; overflow-y: auto;"> <!-- Add scrollable style here -->
          <v-list>
            <v-list-item-group v-if="items.length" multiple v-model="selected">
              <v-list-item v-for="(item, index) in items" :key="item" :class="selected.includes(index) ? 'selected-item' : ''">
                <v-list-item-action>
                  <v-checkbox v-model="selected" :value="index" @click.stop></v-checkbox> <!-- Stopping the propagation here -->
                </v-list-item-action>
                <v-list-item-content>{{ $formatDataLabel(item) }}</v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="cancelSelection">Cancel</v-btn>
          <v-btn color="primary" @click="confirmSelection">Select</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    initialSelection: {
      type: Array,
      default: () => [],
    },

    dialogTitle: {  // New prop
      type: String,
      default: "Select Items" // A default value if no title is provided
    },
    buttonText: { // New prop
      type: String,
      default: "Select" // A default value if no button text is provided
    }
  },
  data() {
    return {
      dialog: false,
      selected: [],
      initialSelected: [],
      confirmedSelection: [],
    };
  },
  mounted() {
    // Set initially selected items based on provided prop
    this.selected = this.initialSelection.map(item => this.items.indexOf(item));
  },

  watch: {
    dialog(val) {
      if (val) {
        this.initialSelected = [...this.selected];
      }
    },
    items: {
      immediate: true,
      handler(newItems, oldItems) {
        if (JSON.stringify(newItems) !== JSON.stringify(oldItems)) {
          this.selected = this.initialSelection.map(item => newItems.indexOf(item));
        }
      }
    },
    initialSelection: {
      immediate: true,
      handler(newSelection) {
        this.selected = newSelection.map(item => this.items.indexOf(item));
      }
    }
  },

  methods: {
    toggleSelection(index) {
      const pos = this.selected.indexOf(index);
      if (pos > -1) {
        this.selected.splice(pos, 1);
      } else {
        this.selected.push(index);
      }
    },
    confirmSelection() {
      this.confirmedSelection = this.selected.map(index => this.items[index]);
      this.$emit("updateSelection", this.confirmedSelection);
      this.dialog = false;
    },

    cancelSelection() {
      this.dialog = false;
      this.selected = [...this.initialSelected];
    },

    removeChip(item) {
      this.confirmedSelection = this.confirmedSelection.filter(i => i !== item);
      this.$emit("updateSelection", this.confirmedSelection);
    }
  },
};
</script>

<style scoped>
  .selected-item {
    background-color: #000000;
  }
</style>