<template>
  <v-container class="pa-3">
    <h1>Player Analysis</h1>
    <p>
      See how you or other players have been playing recently
    </p>
  </v-container>
</template>

<script>

  export default {
    name: 'PlayerAnalysis',

    components: {
    },
  }
</script>
