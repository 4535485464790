<template>
  <v-app>

    <v-app-bar
      dense
      v-cloak app :clipped-left="clipped" height="50"
    >

      <v-app-bar-nav-icon @click="drawer=!drawer">
        <img :src="require('./assets/doh-icon-200px.png')" height="55"/>
      </v-app-bar-nav-icon>

      <v-toolbar-title class="page-title clickable">
        <router-link :to="'/'" class="titlelink">
          Labs
        </router-link>
      </v-toolbar-title>
    </v-app-bar>

    <v-navigation-drawer
      app
      clipped
      v-model="drawer"
      enable-resize-watcher
      :mobile-breakpoint="960"
      width="250"
    >
      <v-list nav>
        <v-list-item-group color="primary">
          <v-list-group
            v-for="(item, i) in items"
            :key="i"
            v-if="item.subitems"
            no-action
            :value="i === openSubmenuIndex"
            @click="openSubmenu(i); goToFirstSubitem(item.subitems)"
          >

          <template v-slot:activator>
            <v-list-item class="menu-item">
              <v-list-item-icon class="menu-icon">
                <v-icon v-text="item.icon" class="menu-icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.text" class="menu-label"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>

            <v-list-item
              v-for="(subitem, j) in item.subitems"
              :key="j"
              :to="subitem.link"
              class="submenu-item"
              exact
            >
              <v-list-item-content class="submenu-content">
                <v-list-item-title v-if="subitem.link.length>0" v-text="'- ' + subitem.text" class="submenu-label"></v-list-item-title>
                <v-list-item-title v-else v-text="'- ' + subitem.text" class="submenu-label" style="color:grey;"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>

          </v-list-group>

          <v-list-item v-else :to="item.link" :key="i" class="menu-item" @click="closeSubmenu(i)">
            <v-list-item-icon class="menu-icon">
              <v-icon v-text="item.icon" class="menu-icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-if="item.link.length>0" v-text="item.text" class="menu-label"></v-list-item-title>
              <v-list-item-title v-else v-text="item.text" class="menu-label" style="color:grey;"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <cookie-law theme="dark-lime">
      <div slot-scope="props">
        <p>
          This site uses cookies to analyse your use of our services and improve your experience.
        </p>
        <v-btn class="skew" @click="props.accept"><span>I accept</span></v-btn>
      </div>        
    </cookie-law>

    <v-main><router-view/></v-main>
  </v-app>
</template>

<script>
import CookieLaw from 'vue-cookie-law'

export default {
  name: 'App',

  components: {
    CookieLaw
  },

  data: () => ({
      indexer_status: 'ok',
      clipped: true,
      drawer: true,
      fixed: true,

      openSubmenuIndex: -1,

      items: [
        { icon: 'mdi-newspaper', text: 'News', link: '/news'},

        {icon: 'mdi-chart-bar', text: 'Usage', link: '/usage', 'subitems': [
            { text: 'Active Players', link: '/players'},
            { text: 'Player Actions', link: '/actions'},
            // { text: 'Player Regions', link: '/player-regions'},
          ]
        },

        { icon: 'mdi-account-multiple', text: 'Players', link: '/leaderboards', subitems: [
            { text: 'Experienced', link: '/player-leaderboards/?preset=main' },
            { text: 'TCN Holders', link: '/player-leaderboards/?preset=tcn' },
            { text: 'TCN Earned', link: '/player-leaderboards/?preset=tcnearned' },
            { text: 'Landlords', link: '/player-leaderboards/?preset=landlords'}, // todo - deprecate, as not very useful
            // { text: 'Battles', link: '' },
            // { text: 'Characters', link: '' },
            // { text: 'Factions', link: '' },
          ] 
        },

        { icon: 'mdi-account-multiple', text: 'Characters', link: '/leaderboards', subitems: [
            { text: 'Experienced', link: '/character-leaderboards/?preset=main' },
            { text: 'Heroic', link: '/character-leaderboards/?preset=combat' },
            { text: 'Healthy', link: '/character-leaderboards/?preset=health' },
            { text: 'Hungry', link: '/character-leaderboards/?preset=hungry' },
            { text: 'Unfortunate', link: '/character-leaderboards/?preset=deaths' },
            // { text: 'Battles', link: '' },
            // { text: 'Characters', link: '' },
            // { text: 'Factions', link: '' },
          ] 
        },

        // {icon: 'mdi-account', text: 'Player Analysis', link: '/player-analysis/?preset=main'},
        // {icon: 'mdi-map-marker-radius', text: 'Tile Analysis', link: '/tile-analysis/?preset=main'},

        {icon: 'mdi-chart-bar', text: 'Activities', link: '/activities', 'subitems': [
            { text: 'by Faction', link: '/activities-by-faction'},
            { text: 'by Region', link: '/activities-by-region'},
          ]
        },

        {icon: 'mdi-chart-bar', text: 'Production', link: '/production-data', 'subitems': [
            { text: 'by Faction', link: '/production-by-faction'},
            { text: 'by Region', link: '/production-by-region'},
            { text: 'Breakdown', link: '/production-filtered'},
          ]
        },

        {icon: 'mdi-chart-bar', text: 'Energy', link: '/energy-data', 'subitems': [
            { text: 'Use by Faction', link: '/energy-use-by-faction'},
          ]
        },

        // {icon: 'mdi-chart-bar', text: 'Area Data', link: '/area-data', 'subitems': [
        //   ]
        // },

        // {icon: 'mdi-chart-bar', text: 'Region Data', link: '/region-data', 'subitems': [
        //   ]
        // },

        {icon: 'mdi-table', text: 'Miscellaneous', link: '/analysis', 'subitems': [
            { text: 'Landlords', link: '/landlords'},
            { text: 'Casualties', link: '/casualties'},
          ]
        },

        {icon: 'mdi-table-large', text: 'Raw Data', link: '/pivot-analysis', 'subitems': [
            { text: 'Recent Actions', link: '/actions-pivot'},
            { text: 'Player-Chars', link: '/players-characters-pivot'},
            { text: 'TCN Transfers', link: '/transfers-pivot'},
          ]
        },

        // {icon: 'mdi-chart-bar', text: 'State Charts', link: '/state', 'subitems': [
        //     { text: 'TCN Distribution', link: ''}, // violin plot of TCN per player per faction
        //     // { text: 'Buildings', link: ''}, // landlords per planet/region/faction
        //     // { text: 'Vehicles', link: ''},
        //     // { text: 'Spaceships', link: ''},
        //     // { text: 'Conflict', link: ''},
        //   ]
        // },

        // {icon: 'mdi-gamepad-variant', text: 'Game', link: '/game'},
      ]

  }),

  methods: {
    goTo(url) {
      window.location.href = url;
    },

    openSubmenu(index) {
      console.log('Opening submenu', index);
      this.openSubmenuIndex = index;
      localStorage.setItem('openSubmenuIndex', this.openSubmenuIndex);
    },
    closeSubmenu(index) {
      console.log('Closing submenu');
      this.openSubmenuIndex = -1;
      localStorage.setItem('openSubmenuIndex', this.openSubmenuIndex);
    },

    goToFirstSubitem(subitems) {
      if (subitems && subitems.length > 0 && subitems[0].link) {
        this.$router.push(subitems[0].link);
      }
    },
  },

  created() {
    const savedOpenSubmenuIndex = localStorage.getItem('openSubmenuIndex');
    this.openSubmenuIndex = savedOpenSubmenuIndex ? parseInt(savedOpenSubmenuIndex, 10) : -1;
  }



};
</script>

<style>

  /*top bar*/
  .v-toolbar--dense .v-toolbar__content, .v-toolbar--dense .v-toolbar__extension {
    background-color: #022f19;
  }

  .v-navigation-drawer__content {
    background-color: #022a15;
  }

/*  .v-toolbar__content, .v-toolbar__extension {
    background-color: #014927; 
  }
*/
    .page-title {
        font-family: 'Orbitron', sans-serif !important;
        font-size:1.3em !important;
        font-weight: bold;
        letter-spacing: .06em;
    }
    .menu-label {
        font-family: 'Orbitron', sans-serif !important;
        font-size:1.01em !important;
        letter-spacing: .06em;
    }
    .submenu-label {
        font-family: 'Orbitron', sans-serif !important;
        font-size:0.99em !important;
        letter-spacing: .06em;
    }

    h1 {
        font-family: 'Orbitron', sans-serif !important;
        font-size: 2em;
        letter-spacing: .06em;
    }
    h2 {
        font-family: 'Orbitron', sans-serif !important;
        font-size: 1.6em;
        letter-spacing: .06em;
    }
    p {
        font-family: 'Orbitron', sans-serif !important;
        font-size: 1.2em;
        letter-spacing: .06em;
    }

    .orbitron {
      font-family: 'Orbitron', sans-serif !important;
      letter-spacing: .06em;
    }

    .mydarkgreen {
      font-family: 'Orbitron', sans-serif;
      letter-spacing: .06em;
    }
    .mymidgreen {
      font-family: 'Orbitron', sans-serif; 
      letter-spacing: .06em;
    }
    .mymiddarkgreen {
      font-family: 'Orbitron', sans-serif; 
      letter-spacing: .06em;
    }
    .mygreen {
      font-family: 'Orbitron', sans-serif; 
      letter-spacing: .06em;
    }

  .v-list-item {
    min-height: 10px!important;
  }


  .submenu-content {
    padding-top: 5px;
    padding-bottom: 5px;
  }


  .menu-item {
    padding: 0!important;
    margin-left: 10!important;
    margin: 0!important;
  }
  .menu-icon {
    padding: 0!important;
    margin-left: 5px!important; /* adjust as needed */
    margin-right: 10px!important; /* adjust as needed */
    font-size: 16px!important;
  }
  .submenu-item {
    padding-left: 45px!important; /* adjust as needed */
    margin-top: 0px!important;
    margin-bottom: 5px!important;
    padding-top: 0px!important;
    padding-bottom: 0px!important;
  }

  .theme--dark.v-list-item {
    padding: 0px;
  }

  .v-list--nav .v-list-item:not(:last-child):not(:only-child), .v-list--rounded .v-list-item:not(:last-child):not(:only-child) {
    margin:0px!important;
  }

  .v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon {
    min-width:24px;
  }

  .v-application a {
      color: #ffffff!important;
  }

  .v-navigation-drawer__content {
      background-color: #022a15;
      padding-top: 10px;
  }

.v-application--is-ltr .v-toolbar__content > .v-btn.v-btn--icon:first-child + .v-toolbar__title, .v-application--is-ltr .v-toolbar__extension > .v-btn.v-btn--icon:first-child + .v-toolbar__title {
    padding-left: 5px;
}

.titlelink {
  text-decoration: none;
}

/*.v-data-table>.v-data-table__wrapper>table>tbody>tr>td, .v-data-table>.v-data-table__wrapper>table>tfoot>tr>td, .v-data-table>.v-data-table__wrapper>table>thead>tr>td {
}
*/
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
    font-family: roboto;
    font-size: 1rem!important;
}

</style>

<style scoped>
  .clickable {
    cursor: pointer;
  }
</style>