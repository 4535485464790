<template>
  <v-container class="pa-5">

    <v-toolbar class="mymidgreen"
      dark
      tabs
    >
    <v-toolbar-title v-text="'Landlords'"></v-toolbar-title>
    </v-toolbar>

    <DataTable 
      :dataUrl="this.$api_endpoint + `/datafiles/landlords.csv`"
      :filteredColumns="['type', 'area_map', 'region', 'category', 'owner', 'faction']"
      :sortedColumns="['buildings']"
    />

  </v-container>
</template>

<script>
  import DataTable from "../components/DataTableSimple.vue";

  export default {
    name: 'Landlords',

    components: {
      DataTable
    },
  }
</script>
