<template>
  <v-container class="pa-5">

  <v-toolbar class="mymidgreen" dark tabs>
    <v-toolbar-title>Industrial Production Breakdown</v-toolbar-title>

      <v-spacer/>
<!-- 
      <v-btn-toggle dense class="mx-2 ma-0" v-model="period_values">
        <v-btn class="primary" value="6">Week</v-btn>
        <v-btn class="primary" value="27">Month</v-btn>
      </v-btn-toggle>
 -->
  </v-toolbar>

    <v-row class="mx-2 mt-2">
      <v-col cols="12" sm="6" md="4">
        <v-select
          :items="$config['products']"
          item-text="name"
          item-value="name"
          v-model="asset"
          :label="'Filter by Product'"
        ></v-select>
      </v-col>
    </v-row>

    <LineChart :title="`Cumulative Production of ${asset} (90 day rolling)`" :colors="$config['faction_colors']" :dataUrl="this.$api_endpoint + `/datafiles/1d_cumulative_production_${asset.replace(' ','')}.csv`" :valueCount="period_values"/>

    <LineChart :title="`Daily Production of ${asset}`" :colors="$config['faction_colors']" :dataUrl="this.$api_endpoint + `/datafiles/1d_production_${asset.replace(' ','')}.csv`" :valueCount="period_values"/>

    <StackedBarChart :title="`Daily Production of ${asset} (stacked)`" :colors="$config['faction_colors']" :dataUrl="this.$api_endpoint + `/datafiles/1d_production_${asset.replace(' ','')}.csv`"  :valueCount="period_values"/>
 
  </v-container>
</template>

<script>
  import LineChart from "../components/LineChart.vue";
  import StackedBarChart from "../components/StackedBarChart.vue";

  export default {
    name: 'ProductionFilteredView',

    components: {
      LineChart,
      StackedBarChart
    },

    data() {
      return {
        period_values: "29",
        asset: 'Food'
      };
    },

  }
</script>
