<template>
  <v-container class="pa-5">

    <v-toolbar class="mymidgreen" dark tabs>
      <v-toolbar-title>AI Generated News</v-toolbar-title>
    </v-toolbar>

      <v-row v-for="post in posts" :key="post.time">
        <v-col>
          <v-card class="post-card">
            <!-- Blog post image -->
            <!-- <v-img :src="post.image" :alt="post.title" height="200"></v-img> -->
            
            <!-- Blog post title -->
            <v-card-title style="font-size:1em;font-family: 'Roboto';">{{ formatDate(post.time) }}</v-card-title>
            
            <!-- Blog post content (shortened for demonstration) -->
            <v-card-text v-html="formatContent(post.content)" style="font-size:1em; font-family: 'Roboto';line-height:1.4em"></v-card-text>
            
            <!-- Read more button -->
<!--             <v-card-actions>
              <v-btn color="primary" :to="`/blog/${post.id}`">Read more</v-btn>
            </v-card-actions>
 -->
          </v-card>
        </v-col>
      </v-row>

  </v-container>
</template>

<script>

  export default {
    name: 'News',

    components: {
    },

    data () {
      return {
        posts: [],
        loading: true
      }
    },

    methods: {
      async fetchData() {
        this.loading = true;
        let url = this.$api_endpoint + `/news?limit=7`;
        console.log(url);
        this.$axios.get(url)
          .then(response => {
            console.log(response);
            this.posts = response.data;
          })
          .catch(err => {
            console.error(err);
          })
          .finally(() => {
            console.log('Done loading!');
            this.loading = false;
          });
      },

      formatDate(timestamp) {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // +1 because months are 0-indexed
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}`;
      },
      formatContent(content) {
        return content.replace(/\n\n/g, '<br><br>');
      }

    },

    mounted() {
      this.fetchData();
    },

  }
</script>

<style scoped>

</style>