<template>
  <div v-if="visible">
    <div class="chart" ref="chartdiv"></div>
  </div>
</template>

<script>
import Plotly from 'plotly.js';
import axios from 'axios';

export default {
  name: 'StackedBarChartPlotly',
  props: {
    title: {
      type: String,
      required: true,
    },
    dataUrl: {
      type: String,
      required: true,
    },
    colors: {
      type: Array,
      required: true,
      default: null,
    },
    valueCount: {
      type: [Number, String],
      default: 24,
      validator(value) {
        return !isNaN(Number(value));
      },
    },
  },
  data() {
    return {
      chart: null,
      visible: true,
      dataLastModified: null,
    };
  },
  methods: {
    redrawChart(valueCount) {
      this.visible = true;
      axios.get(this.dataUrl).then((response) => {
        this.dataLastModified = response.headers['last-modified'];
        let data = response.data
          .trim()
          .split('\n')
          .map((row) => row.split(','));

        const headers = data[0].slice(1);
        data = [data[0]].concat(data.slice(-valueCount + 1));

        const chartData = data.slice(1).map((row) => {
          const obj = { date: row[0] };
          headers.forEach((header, index) => {
            const value = row[index + 1];
            obj[header] = value && !isNaN(Number(value)) ? Number(value) : null;
          });
          return obj;
        });

        const offsetHours = new Date().getTimezoneOffset() / -60;
        const plotlyData = headers.map((header, index) => ({
            type: 'bar',
            name: header,
            x: chartData.map((d) => {
              const date = new Date(d.date);
              date.setHours(date.getHours() + offsetHours);
              return date.toISOString();
            }),
            y: chartData.map((d) => d[header]),
            marker: {
              color: this.colors ? this.colors[index % this.colors.length] : null,
            },
            hovertemplate: '<b>%{fullData.name}</b><br>Time: %{x}<br>Count: %{y}<extra></extra>',
        }));

        const layout = {
          margin: {
            l: 50,
            r: 50,
            b: 60,
            t: 60
          },
          title: {
            text: `<span style='letter-spacing: 0.06em;'>${this.title}</span>`,
            font: {
              color: 'white',
              family: 'Orbitron, Arial, sans-serif'
            }
          },
          paper_bgcolor: '#000',
          plot_bgcolor: '#000',
          barmode: 'stack',
          bargap: this.valueCount == 24 ? 0.15 : 0.0,
          xaxis: {
            type: 'date',
            titlefont: {
              color: 'white'
            },
            tickfont: {
              color: 'white'
            },
            gridcolor: '#444',
          },
          yaxis: {
            titlefont: {
              color: 'white'
            },
            tickfont: {
              color: 'white'
            },
            gridcolor: '#444'
          },
          showlegend: true,
          legend: {
            font: {
              color: 'white'
            },
            traceorder: 'normal',
            orientation: 'h',
            xanchor: 'center',
            x: 0.5,
            yanchor: 'top',
            y: -0.1
          },
        };

        try {
          Plotly.newPlot(this.$refs.chartdiv, plotlyData, layout);
        } catch (e) {
          this.visible = false;
        }

      }).catch((error) => {
        this.visible = false;
        if (error.response && error.response.status === 404) {
          return; // Silently return on 404
        }
        console.error(error); // Log other errors
      });
    },
  },
  watch: {
    valueCount(newCount) {
      this.redrawChart(newCount);
    },
    dataUrl() {
      this.redrawChart(this.valueCount);
    },
  },
  mounted() {
    this.redrawChart(this.valueCount);
  },
  beforeDestroy() {
    if (this.chart) {
      Plotly.purge(this.$refs.chartdiv);
    }
  },
};
</script>

<style scoped>
.chart {
  width: 100%;
  height: 500px;
}
</style>
