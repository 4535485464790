<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <!-- :src="require('../assets/logo.svg')" -->
        <v-img
          :src="require('../assets/logo-700px.png')"
          class="my-3"
          contain
          height="300"
        />
      </v-col>

      <v-col class="mb-4">
        <h1 class="font-weight-bold mb-3">
          Welcome to DoH Labs
        </h1>

        <p class="subheading font-weight-regular">
          Leaderboards and Strategic Analysis Tools
        </p>
      </v-col>

      <v-col
        class="mb-5"
        cols="12"
      >
        <h2 class="font-weight-bold mb-3">
          Where first?
        </h2>

        <v-row justify="center">
          <router-link
            v-for="(next, i) in whatsNext"
            :key="i"
            :to="next.href"
            class="subheading mx-3"
          >
            {{ next.text }}
          </router-link>
        </v-row>
      </v-col>


    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'HelloWorld',

    data: () => ({
      whatsNext: [
        {
          text: 'Main Player Leaderboard',
          href: '/player-leaderboards/?preset=main',
        },
        {
          text: 'Active Players Charts',
          href: '/players',
        },
        // {
        //   text: 'Underexploited Zones',
        //   href: '',
        // },
        // {
        //   text: 'Best Times to Play',
        //   href: '',
        // },
      ],
    }),
  }
</script>
