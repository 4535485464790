<template>
  <v-container class="pa-5">

  <v-toolbar class="mymidgreen" dark tabs>
    <v-toolbar-title>Industrial Production by Faction</v-toolbar-title>

      <v-spacer/>
<!-- 
      <v-btn-toggle dense class="mx-2 ma-0" v-model="period_values">
        <v-btn class="primary" value="57">Week</v-btn>
        <v-btn class="primary" value="225">Month</v-btn>
      </v-btn-toggle>
 -->
    </v-toolbar>
    <p></p>

    <StackedBarChart 
      v-for="faction in $config['factions']" 
      :key="faction.name"
      :title="`Daily Production by ${faction.name}`" 
      :dataUrl="`${$api_endpoint}/datafiles/1d_faction_production_${faction.id}.csv`"
      :valueCount="period_values" 
    />

    <StackedBarChart :title="'Unaligned'" :dataUrl="this.$api_endpoint + `/datafiles/1d_faction_production_0.csv`"  :valueCount="period_values"/>

  </v-container>
</template>

<script>
  import StackedBarChart from "../components/StackedBarChart.vue";

  export default {
    name: 'ProductionFactionView',

    components: {
      StackedBarChart
    },

    data() {
      return {
        period_values: "29"
      };
    },

  }
</script>
