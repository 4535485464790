<template>
  <v-container class="pa-5">

    <v-toolbar class="mymidgreen" dark tabs>
      <v-toolbar-title>Activities by Region</v-toolbar-title>

      <v-spacer/>
      <v-btn-toggle dense class="mx-2 ma-0" v-model="period_values">
<!-- 
        <v-btn class="primary" value="57">Week</v-btn>
        <v-btn class="primary" value="225">Month</v-btn>
 -->
      </v-btn-toggle>

    </v-toolbar>
    <p></p>

    <template v-for="(regions, area_map) in $config['area_map_regions']">
      <StackedBarChart 
        v-for="region in regions"
        :key="region.name"
        :title="`${$formatDataLabel(area_map)} - ${region.name}`" 
        :dataUrl="`${$api_endpoint}/datafiles/1d_region_activities_${area_map}_${region.name.replace(' ', '')}.csv`"
        :valueCount="period_values" 
      />
    </template>

  </v-container>
</template>

<script>
  import StackedBarChart from "../components/StackedBarChart.vue";

  export default {
    name: 'ActivitiesRegionView',

    components: {
      StackedBarChart
    },

    data() {
      return {
        period_values: "29"
      };
    },

  }
</script>
