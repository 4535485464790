<template>
  <v-container class="pa-3">
    <!-- <h1>Leaderboards</h1> -->
    <DataTable
      :title="title"
      :apiEndpoint="apiEndpoint"
      :type="type"
      />
  </v-container>
</template>

<script>
  import DataTable from "../components/DataTable.vue";

  export default {
    name: 'Leaderboards',

    components: {
      DataTable
    },

    props: {
      title: String,
      type: String,
      apiEndpoint: String,
    },

  }
</script>
