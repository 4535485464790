<template>
  <v-container class="pa-0 roboto">
    <v-row class="mx-2 my-1 py-0">
      <v-col class="py-0" v-for="col in filteredColumns" :key="col" cols="12" sm="6" md="3">
        <v-select
          :items="columnOptions(col)"
          v-model="filters[col]"
          :label="'Filter by ' + $formatDataLabel(col)"
          clearable
        ></v-select>
      </v-col>
    </v-row>

      <v-data-table
        :items="filteredItems"
        :headers="headers"
        :sort-by="initialSortBy"
        :sort-desc="initialSortDesc"
        class="Orbitron"
      >
      </v-data-table>

  </v-container>
</template>


<script>
import axios from "axios";

export default {
  props: {
    dataUrl: String,
    filteredColumns: Array,
    sortedColumns: Array,
  },
  data() {
    return {
      items: [],
      filters: {},
      headers: [],
    };
  },
  computed: {
    initialSortBy() {
      return this.sortedColumns || [];
    },
    initialSortDesc() {
      return this.sortedColumns ? this.sortedColumns.map(() => true) : [];
    },
    filteredItems() {
      return this.items.filter((item) =>
        this.filteredColumns.every((col) =>
          this.filters[col] ? item[col] === this.filters[col] : true
        )
      );
    },
  },
  methods: {
    columnOptions(col) {
      return [...new Set(this.items.map((item) => item[col]))];
    },
  },
  async created() {
    try {
      const response = await axios.get(this.dataUrl);
      let data = response.data
        .trim()
        .split('\n')
        .map((row) => row.split(','));

      const headers = data[0];
      this.headers = headers.map((key) => {
        return { text: this.$formatDataLabel(key), value: key };
      });

      const rows = data.slice(1);
      this.items = rows.map((row) => {
        const obj = {};
        headers.forEach((header, index) => {
          obj[header] = row[index];
        });
        return obj;
      });

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  },
};
</script>

<style scoped>

.roboto {
  font-family: Roboto;
}
</style>

<style>
  .v-data-table-header {
    font-family: 'Orbitron', sans-serif !important;
    letter-spacing: .06em;
  }
</style>

<!-- <style>
  table > .v-data-table__wrapper > table > thead > tr > th {
    height: 36px!important;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    height: 36px!important;
  }
</style> -->

