<template>
  <v-container class="pa-5">

    <v-toolbar class="mymidgreen" dark tabs>
      <v-toolbar-title>Energy Use by Faction</v-toolbar-title>

      <v-spacer/>
<!--       
      <v-btn-toggle dense class="mx-2 ma-0" v-model="period_values">
        <v-btn class="primary" value="168">Week</v-btn>
        <v-btn class="primary" value="672">Month</v-btn>
      </v-btn-toggle>
 -->
    </v-toolbar>
    <p></p>

    <LineChart :title="'Cumulative Energy Use (90 day rolling)'" :colors="$config['faction_colors']" :dataUrl="this.$api_endpoint + `/datafiles/1d_faction_cumulative_energy_use.csv`" :valueCount="period_values"/>

    <LineChart :title="'Daily Energy Use'" :colors="$config['faction_colors']" :dataUrl="this.$api_endpoint + `/datafiles/1d_faction_energy_use.csv`" :valueCount="period_values"/>


  </v-container>
</template>

<script>
  import StackedAreaChart from "../components/StackedAreaChart.vue";
  import LineChart from "../components/LineChart.vue";

  export default {
    name: 'FactionEnergyUseView',

    components: {
      StackedAreaChart,
      LineChart
    },

    data() {
      return {
        period_values: "29"
      };
    },

  }
</script>
